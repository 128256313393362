.login .error {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
}

.login button {
  background-color: #81ac2c;
}

.login input {
  outline-color: #81ac2c;
}
