.header {
  position: relative;
  min-height: 35px;
}

.header.loggato img {
  position: absolute;
  left: 0;
  top: 0;
}

.header .ente {
  min-height: 35px;
}

.header .ente h3 {
  margin-top: 5px;
  margin-bottom: 6px;
}

.header .ente p {
  margin-top: 0;
  margin-bottom: 0;
}

.header .menu {
  background-color: #81ac2c;
}

@media print {
  .header .menu {
    display: none !important;
  }
}
