.tabella-cedolino {
  border-collapse: collapse;
}

.tabella-cedolino th {
  font-weight: 600;
  border-bottom: 2px solid rgba(0, 0, 0, 1);
  text-align: right;
  padding: 3px 6px;
  background-color: #fff;
}

.tabella-cedolino td {
  text-align: right;
  padding: 3px 6px;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.tabella-cedolino th.tl,
.tabella-cedolino td.tl {
  text-align: left;
}

.tabella-cedolino .lordo {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.tabella-cedolino .totale {
  border: 2px solid black;
  background-color: #c2ea75;
}
