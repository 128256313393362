.cedolini table {
  border-collapse: collapse;
}

.cedolini table td,
.cedolini table th {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.cedolini .vedi-cedolino {
  background-color: #81ac2c;
}
